import InputAdornment from "@mui/material/InputAdornment"
import { ComponentProps } from "react"
import { NumberInput } from "react-admin"

export function PercentInput(props: ComponentProps<typeof NumberInput>) {
  return (
    <NumberInput
      {...props}
      parse={(value) => (value ? parseFloat(value) / 100 : null)}
      format={(value) => (value ? parseFloat(value) * 100 : null)}
      // max={100}
      min={0}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
    />
  )
}
