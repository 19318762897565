import {
  DateField,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  Title,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { MoneyField } from "../Components/MoneyField"
import { InvoiceItemList } from "./InvoiceItemList"

export function InvoiceShow() {
  const [organization_id] = useOrganizationId()
  return (
    <Show title={<Title> Invoice </Title>}>
      <SimpleShowLayout>
        <ReferenceField source="project_id" reference="projects" />
        <TextField source="status" textTransform="capitalize" />
        <DateField source="invoice_date" />
        <DateField source="due_date" />

        <ReferenceManyField
          target="invoice_item_id"
          reference="invoice_items"
          filter={{ "deleted_at@is": null, organization_id }}
        >
          <InvoiceItemList />
        </ReferenceManyField>
        <MoneyField source="total_amount" />
        {/* <DateField source="created_at" />
        <DateField source="updated_at" /> */}
      </SimpleShowLayout>
    </Show>
  )
}
