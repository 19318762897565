import { useDisclosure } from "@dwarvesf/react-hooks"
import { Dialog, DialogTitle } from "@mui/material"

import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined"
import { Show } from "@react-admin/ra-rbac"
import {
  Button,
  DateField,
  EditButton,
  ReferenceField,
  ResourceContextProvider,
  SimpleShowLayout,
  TopToolbar,
  useRecordContext,
  WithRecord,
} from "react-admin"
import { ServiceIntervalField } from "../../Components/ServiceIntervalField"
import { CompleteServiceForm } from "./CompleteServiceForm"

function CompleteServiceButton(props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const record = useRecordContext(props)
  return (
    <>
      <Button
        label="Complete Service"
        onClick={onOpen}
        startIcon={<ScheduleOutlinedIcon />}
      />
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Complete Service</DialogTitle>
        <ResourceContextProvider value="service_records">
          <CompleteServiceForm
            toolServiceRecords={[record]}
            serviceTemplateId={record.service_template_id}
          />
        </ResourceContextProvider>
      </Dialog>
    </>
  )
}

export const ToolServiceShow = () => (
  <Show
    actions={
      <TopToolbar>
        <WithRecord render={() => <CompleteServiceButton />} />
        <EditButton />
      </TopToolbar>
    }
  >
    <SimpleShowLayout>
      <ReferenceField source="tool_id" reference="tools" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
      />
      <ReferenceField source="crew_member_id" reference="crew_members" />
      <DateField source="due_date" />
      <ReferenceField
        source="service_template_id"
        reference="service_templates"
        label="Recurring" // TODO: i18n
      >
        <ServiceIntervalField source="service_interval" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
