import InputAdornment from "@mui/material/InputAdornment"
import { useMemo } from "react"
import {
  DateTimeInput,
  FileField,
  FileInput,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  useCreate,
  useGetOne,
  useRedirect,
} from "react-admin"
import { useOrganizationId } from "../../../hooks/useOrganizationId"
import { CrewMemberInput } from "../../Tool/CrewMemberInput"

export function CompleteServiceForm({ serviceTemplateId, toolServiceRecords }) {
  const [organization_id] = useOrganizationId()
  const redirect = useRedirect()
  const [create] = useCreate()
  const handleCreate = (record) => {
    Promise.all(
      toolServiceRecords.map((toolServiceRecord) => {
        const { id, tool_id } = toolServiceRecord
        const { notes, cost, attachment, crew_member_id, completed_at } = record
        const data = {
          tool_id,
          tool_service_id: id,
          organization_id,
          notes,
          cost,
          attachment,
          crew_member_id,
          completed_at,
        }
        return create("service_records", { data })
      })
    ).then(() => {
      redirect("list", "service_records")
    })
  }
  const now = useMemo(() => new Date(), [])
  const { data, isLoading } = useGetOne("service_templates", {
    id: serviceTemplateId,
  })
  if (isLoading) return null
  const { is_utilization_based, service_utilization_unit } = data ?? {}
  return (
    <SimpleForm onSubmit={handleCreate}>
      <TextInput source="notes" validate={required()} multiline rows={3} />
      <TextInput source="cost" />
      <FileInput source="attachment" multiple={false}>
        <FileField source="src" title="title" target="_blank" />
      </FileInput>
      <CrewMemberInput
        source="crew_member_id"
        organization_id={organization_id}
        showAutoToolroom={false}
      />
      <DateTimeInput source="completed_at" defaultValue={now} />
      {is_utilization_based && (
        <NumberInput
          source="competed_utilization"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {service_utilization_unit}
              </InputAdornment>
            ),
          }}
        />
      )}
    </SimpleForm>
  )
}
