import {
  ReferenceInput,
  SimpleForm,
  TextInput,
  useCreate,
  useRedirect,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

export function InvoiceItemFromAccounting({ accountingRecords, projectId }) {
  const [organization_id] = useOrganizationId()
  const redirect = useRedirect()
  const [create] = useCreate()
  const handleCreate = (record) => {
    Promise.all(
      accountingRecords.map((accountingRecord) => {
        const { tool_id, quantity } = accountingRecord
        // quantity and unit and unit price should come from the accounting record
        // line_total is the capped amount
        const { invoice_id, description } = record
        const data = {
          invoice_id,
          organization_id,
          tool_id,
          quantity,
          description,
        }
        return create("invoice_lines", { data })
      })
    ).then(() => {
      redirect("list", "invoices")
    })
  }
  return (
    <SimpleForm onSubmit={handleCreate}>
      <ReferenceInput
        source="invoice_id"
        reference="invoices"
        filter={{
          organization_id,
          project_id: projectId,
          "deleted_at@is": null,
        }}
      />
      <TextInput source="description" multiline rows={3} />
    </SimpleForm>
  )
}
