import { NumberField } from "react-admin"

export function MoneyField(props) {
  return (
    <NumberField
      {...props}
      options={{
        ...props.options,
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      }}
    />
  )
}
