import { DateInput, SelectInput, SimpleForm } from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"
import { ProjectInput } from "../Tool/ProjectInput"

export function InvoiceForm() {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm values={{ organization_id }}>
      <ProjectInput organization_id={organization_id} />
      <DateInput source="invoice_date" />
      <DateInput source="due_date" />
      <SelectInput
        source="status"
        choices={[
          { id: "draft", name: "Draft" },
          { id: "final", name: "Final" },
        ]}
      />
    </SimpleForm>
  )
}
