import * as Sentry from "@sentry/react"
import posthog, { PostHog } from "posthog-js"
import { supabase } from "./admin/Providers/supabase"

let posthogClient: PostHog
if (window.location.hostname === "localhost") {
  console.log("Not logging errors in development")
} else {
  posthogClient = posthog.init(
    // spellchecker: disable-next-line
    "phc_62sahB7zFE2VI4IuNi2EkqZ9uv4XjkaukrAvrzvAT1r",
    {
      api_host: "https://us.i.posthog.com",
    }
  )
  Sentry.init({
    dsn: "https://ec4bcb01ed5640b9ab07f25eb451e6a6@o1023426.ingest.sentry.io/4505095156072448",
    integrations: [
      posthog.sentryIntegration({
        organization: "tooltribe",
        projectId: 4505095156072448,
        severityAllowList: "*", // optional: here is set to handle captureMessage (info) and captureException (error)
      }),
    ],
  })

  supabase.auth.onAuthStateChange((event, session) => {
    if (event === "SIGNED_IN" && session?.user) {
      posthog.identify(session?.user?.id, {
        name: session.user.user_metadata?.full_name,
        email: session.user.email,
        phone: session.user.phone,
      })
      Sentry.setUser({
        id: session.user.id,
        email: session.user.email,
        phone: session.user.phone,
      })
    }
  })
}

export default posthogClient
