import { SimpleForm } from "@react-admin/ra-rbac"
import {
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from "react-admin"
import { useOrganizationId } from "../../hooks/useOrganizationId"

export function AccountingForm() {
  const [organization_id] = useOrganizationId()
  return (
    <SimpleForm defaultValues={{ organization_id }}>
      <ReferenceInput source="project_id" reference="projects">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="category" validate={required()} />
      <ReferenceInput source="tool_id" reference="tools">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <DateInput source="period_start" validate={required()} />
      <DateInput source="period_end" validate={required()} />
      <DateInput source="bill_date" validate={required()} />
      <NumberInput source="daily_rent" validate={required()} />
      <NumberInput source="weekly_rent" />
      <NumberInput source="monthly_rent" />
      <NumberInput source="rental_cost" />
      <NumberInput source="period_rent" />
      <TextInput source="bill_type" validate={required()} />
      <NumberInput source="value" />
      <NumberInput source="quantity" validate={required()} />
      <NumberInput source="capped_rent" />
      <NumberInput source="remaining_cap" />
      <NumberInput source="cap_percentage" />
      <TextInput source="cost_code" />
    </SimpleForm>
  )
}
